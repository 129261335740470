import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Community Engagement Challenge: `}<a parentName="li" {...{
          "href": "https://www.gnome.org/challenge/"
        }}>{`https://www.gnome.org/challenge/`}</a></li>
      <li parentName="ul">{`Linux-filer i Windows filhanterare: `}<a parentName="li" {...{
          "href": "https://blogs.windows.com/windowsexperience/2020/04/08/announcing-windows-10-insider-preview-build-19603/"
        }}>{`https://blogs.windows.com/windowsexperience/2020/04/08/announcing-windows-10-insider-preview-build-19603/`}</a></li>
      <li parentName="ul">{`Qt i blåsväder: `}<a parentName="li" {...{
          "href": "https://www.phoronix.com/scan.php?page=news_item&px=More-Interest-Possible-Qt-Fork"
        }}>{`https://www.phoronix.com/scan.php?page=news_item&px=More-Interest-Possible-Qt-Fork`}</a></li>
      <li parentName="ul">{`Contact tracing: `}<a parentName="li" {...{
          "href": "https://blog.google/inside-google/company-announcements/apple-and-google-partner-covid-19-contact-tracing-technology"
        }}>{`https://blog.google/inside-google/company-announcements/apple-and-google-partner-covid-19-contact-tracing-technology`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Opera fifflar desperat: `}<a parentName="li" {...{
          "href": "https://gadgets.ndtv.com/apps/news/opera-offering-predatory-loans-via-android-apps-in-india-kenya-and-nigeria-hindenburg-research-2166908"
        }}>{`https://gadgets.ndtv.com/apps/news/opera-offering-predatory-loans-via-android-apps-in-india-kenya-and-nigeria-hindenburg-research-2166908`}</a></li>
      <li parentName="ul">{`Google bannar Zoom: `}<a parentName="li" {...{
          "href": "https://www.businessinsider.sg/google-bans-zoom-from-employee-computers-due-to-security-concerns-2020-4"
        }}>{`https://www.businessinsider.sg/google-bans-zoom-from-employee-computers-due-to-security-concerns-2020-4`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Kul AMA med Greg: `}<a parentName="li" {...{
          "href": "https://www.reddit.com/r/linux/comments/fx5e4v/im_greg_kroahhartman_linux_kernel_developer_ama/"
        }}>{`https://www.reddit.com/r/linux/comments/fx5e4v/im_greg_kroahhartman_linux_kernel_developer_ama/`}</a></li>
      <li parentName="ul">{`Mozilla ny VD med fokus på Firefox (igen): `}<a parentName="li" {...{
          "href": "https://blog.mozilla.org/blog/2020/04/08/mitchell-baker-named-ceo-of-mozilla/"
        }}>{`https://blog.mozilla.org/blog/2020/04/08/mitchell-baker-named-ceo-of-mozilla/`}</a><ul parentName="li">
          <li parentName="ul">{`Firefox 75 ute: `}<a parentName="li" {...{
              "href": "https://www.mozilla.org/en-US/firefox/75.0/releasenotes/"
            }}>{`https://www.mozilla.org/en-US/firefox/75.0/releasenotes/`}</a></li>
        </ul></li>
      <li parentName="ul">{`Ersätt med öppen mjukvara: `}<a parentName="li" {...{
          "href": "https://switching.software/"
        }}>{`https://switching.software/`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Vim`}</h3>
    <ul>
      <li parentName="ul">{`Vi flyttade startdatum (pågår till 24/4)`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://vim-adventures.com/"
            }}>{`https://vim-adventures.com/`}</a></li>
        </ul></li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Tabbar per avsnitt (undrar Christer)`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      